import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../components/layout";

import "../styles/basic-page.scss";

const TermsPage = ({ data }) => {
  const text = data.sanitySiteSettings._rawTerms;

  return (
    <Layout pageTitle="Terms">
      <div className="text">
        <BlockContent blocks={text} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanitySiteSettings(_id: { eq: "single-siteSettings" }) {
      _rawTerms
    }
  }
`;

export default TermsPage;
